import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography, TextField, Icon, Button, Tabs, Tab, Breadcrumbs, Link, Popover, Drawer, Autocomplete, Switch, Grid, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { IconDots, IconLink, IconPlus, IconPlayerPlay, IconRefresh } from '@tabler/icons-react';
import IconButton from '@material-ui/core/IconButton';
import juniperImg from "../icons/juniper.png";
import saltoImg from "../icons/salto.png";
import slackImg from "../icons/slack.png";
import loaderImg from "../icons/Loader.png";
import mainLogo from "../icons/logo.png";
import ArrowIcon from "../icons/arrows-right-left.png";
import juniperLogoImg from "../icons/juniper-networks-logo-4 3.png"
import ConnectSalto from './ConnectSalto';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { useSelector } from 'react-redux';
import DataSyncStatusModal from '../Modal/DataSyncStatusModal';

const dataSyncMessageBasedOnStatus = {
	'IN_PROGRESS': 'in progress',
	'ERROR': 'error',
	'COMPLETED': 'completed'
}
import useToast from '@fuse/hooks/useToast';
import axios from "@fuse/utils/axios";
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { formatDate } from 'helper/common';
import { toggleProgressBarVisibility } from '../store/dataSyncReducer';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';

function Connector() {
	const dataSyncReducer = useSelector(({ ConnectorsApp }) => ConnectorsApp.dataSyncReducer);
	const { broadcastChannel } = usePusher();
	const useStyles = makeStyles(theme => (
		{
			drawerWidth: {
				width: 520,
				borderTopLeftRadius: '8px',
				borderBottomLeftRadius: '8px',
				overflowX: 'hidden',
				overflowY: 'hidden'
			},
		}
	))

    const classes = useStyles()

	const items = ['Network Access Control', 'Building Management', 'Productivity', 'Communication', 'Hosting', 'Asset and System Management'];

	const [selectedItems, setSelectedItems] = useState(['Network Access Control', 'Building Management', 'Productivity', 'Communication', 'Hosting', 'Asset and System Management']);
  
	const[allSelected, setAllSelected]  = useState(selectedItems.length === items.length);

	const someSelected = selectedItems.length > 0 && !allSelected;
	const dispatch = useDispatch();
	const toast = useToast(dispatch);

	const handleSelectAll = (event) => {
	  if (event.target.checked) {
		setSelectedItems(items); 
		setAllSelected(true)
	  } else {
		setSelectedItems([]);
		setAllSelected(false)
	  }
	};
  
	const handleItemChange = (event, item) => {
	  if (event.target.checked) {
		setSelectedItems([...selectedItems, item]);
	  } else {
		setSelectedItems(selectedItems.filter((selected) => selected !== item));
	  }

	  if(selectedItems.length === (items.length - 1) && event.target.checked){
		setAllSelected(true)
	  }else{
		setAllSelected(false)
	  }

	  if(item !== 'Network Access Control'){
		if(!selectedItems.includes('Network Access Control')){
			setConnectors([])
		}
	  }else{
		if(!event.target.checked){
			setConnectors([])
		}else{
			setConnectors([
				{
					name: 'Juniper',
					type: 'Network Access Control',
					desc: 'Innovative Networking Solutions for Reliable, Secure, and Scalable IT Infrastructure and Connectivity.',
					syncType: 'connected',
					visible: true
				},
				{
					name: 'Salto',
					type: 'Network Access Control',
					desc: 'All-in-one smart brand that provides smart access solutions, particularly for residential living.',
					syncType: 'not connected',
					visible: true
				},
				// {
				// 	name: 'Slack',
				// 	type: 'Communication',
				// 	desc: `It's the app where you can instantly reach your team on the go or at your desk.`,
				// 	syncType: 'broken'
				// }
			])
		}
	  }
	};

	const [value, setVal] = useState(0);

	function displayConnectorListBasedOnTabs(newValue){
		if(newValue === 0){//all
			setConnectors([
				{
					name: 'Juniper',
					type: 'Network Access Control',
					desc: 'Innovative Networking Solutions for Reliable, Secure, and Scalable IT Infrastructure and Connectivity.',
					syncType: 'connected',
					visible: true
				},
				{
					name: 'Salto',
					type: 'Network Access Control',
					desc: 'All-in-one smart brand that provides smart access solutions, particularly for residential living.',
					syncType: 'not connected',
					visible: true
				},
			])
		}else if(newValue === 1){//connected
			const updatedConnectors = connectors.map(connector => {
				return {
					...connector,
					visible: connectorDashboardMatrix.connected.includes(connector.name)
				};
			});			
			setConnectors(updatedConnectors)
		}
		else if(newValue === 2){//disconnected
			const updatedConnectors = connectors.map(connector => {
				return {
					...connector,
					visible: connectorDashboardMatrix.disconnected.includes(connector.name)
				};
			});			
			setConnectors(updatedConnectors)
		}
		else if(newValue === 3){//broken
			setConnectors([
				{
					name: 'Juniper',
					type: 'Network Access Control',
					desc: 'Innovative Networking Solutions for Reliable, Secure, and Scalable IT Infrastructure and Connectivity.',
					syncType: 'connected',
					visible: false
				},
				{
					name: 'Salto',
					type: 'Network Access Control',
					desc: 'All-in-one smart brand that provides smart access solutions, particularly for residential living.',
					syncType: 'not connected',
					visible: false
				},
			])
		}
	}
	const handleChange = (event, newValue) => {
		setVal(newValue);
		displayConnectorListBasedOnTabs(newValue)
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [ connectorName, setConnectorName ] = useState('')
	const handleClick = (event, connector) => {
		setAnchorEl(event.currentTarget);
		setConnectorName(connector)
	};

	const handleClose = () => {
		setAnchorEl(null);
		setConnectorName('')
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const [openJuniperDrawer, setOpenJuniperDrawer] = useState(false);
	const [openSaltoDrawer, setOpenSaltoDrawer] = useState(false);
	const [juniperConnectionType, setJuniperConnectionType] = useState();
	const [isEditing, setIsEdit] = useState(false);


	const handleOpenForm = (event, edit=false) => {
		if (event === 'Juniper') {
			setOpenJuniperDrawer(true);
			setOpenSaltoDrawer(false);
			edit ? setIsEdit(true) : setIsEdit(false)
		} else if (event === 'Salto') {
			setOpenJuniperDrawer(false);
			setOpenSaltoDrawer(true);
		} 
		handleClose()
	}

	const handleJuniperCloseForm = () => {		
		setOpenJuniperDrawer(false);
		fetchCompanyDetails()
	}

	const handleCloseForm = () => {		
		setOpenSaltoDrawer(false);
	}

	const connectionOptions = [
		{ label: 'Organization', id: 1 },
  		{ label: 'Site', id: 2 },
	];

	const regionOptions = [
		{ label: "Global01:api.mist.com", id: 1 },
  		{ label: "Global02:api.gc1.mist.com", id: 2 },
  		{ label: "Global03:api.ac2.mist.com", id: 3 },
  		{ label: "Global04:api.gc2.mist.com", id: 4 },
  		{ label: "Europe01:api.eu.mist.com", id: 5 },
	];

	const [connectors, setConnectors ]= useState([
		{
			name: 'Juniper',
			type: 'Network Access Control',
			desc: 'Innovative Networking Solutions for Reliable, Secure, and Scalable IT Infrastructure and Connectivity.',
			syncType: 'connected',
			visible: true
		},
		{
			name: 'Salto',
			type: 'Network Access Control',
			desc: 'All-in-one smart brand that provides smart access solutions, particularly for residential living.',
			syncType: 'not connected',
			visible: true
		},
		// {
		// 	name: 'Slack',
		// 	type: 'Communication',
		// 	desc: `It's the app where you can instantly reach your team on the go or at your desk.`,
		// 	syncType: 'broken'
		// }
	])
	
	const [openReconnectModal, setOpenReconnectModal] = useState(false);

	const [openHowWorksModal, setOpenHowWorksModal] = useState(false);

	const [openDisconnectModal, setOpenDisconnectModal] = useState(false);

	const [openAuthErrorModal, setOpenAuthErrorModal] = useState(false);

	const [openSync0Modal, setOpenSync0Modal] = useState(false);

	const [openSync1Modal, setOpenSync1Modal] = useState(false);

	const [syncFailed, setSyncFailed] = useState(false);


	const [openDataSyncStatusModal, setOpenDataSyncStatusModal] = useState(false);
	function handleOpenDataSyncStatusModal(){
		setOpenDataSyncStatusModal(true)
	}
	function handleCloseDataSyncStatusModal(){
		setOpenDataSyncStatusModal(false)
	}
	const [checkStatusModal, setCheckStatusModal] = useState(false);
	const [openJuniperTestConnectionModal1, setOpenJuniperTestConnectionModal1] = useState(false);
	const [openJuniperTestConnectionModal2, setOpenJuniperTestConnectionModal2] = useState(false);
	const [juniperTestConnection, setJuniperTestConnection] = useState(false);
	const [juniperTestConnectionFailed, setJuniperTestConnectionFailed] = useState(false);
	const [juniperTestConnectionIsLoading, setJuniperTestConnectionIsLoading] = useState(false);
	const [companyDetails, setCompanyDetails] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [juniperDataSync, setJuniperDataSync] = useState(false);
	const [juniperDataSyncFailed, setJuniperDataSyncFailed] = useState(false);
	const [juniperSyncDataDate, setJuniperSyncDataDate] = useState();
	const [search, setSearch] = useState(null);
	const user = useCurrentUser();
	const [ connectorDashboardMatrix, setConnectorDashboardMatrix ] = useState({
		all: 2,
		connected: 0,
		disconnected: 0,
		broken: 0
	})
	const closeSyncFailed = () => {		
		setSyncFailed(false);
		setOpenSync1Modal(false);
	}

	const syncCloseModal = () => {
		setOpenSync1Modal(false);
	}

	const juniperTestConnectionCloseModal = () => {
		setJuniperTestConnection(false);
		setJuniperTestConnectionIsLoading(false);
		setJuniperTestConnectionFailed(false);
	}

	useEffect(() => {
		if (openSync1Modal) {
			const timer = setTimeout(() => {
			  setSyncFailed(true);
			}, 3000);
	  
			return () => clearTimeout(timer);
		}
	}, [openSync1Modal]);

	const [progressStatus, setProgressStatus] = useState(0);
    const defaultValues = {
        connectionType: null,
        organizationName: '',
        organizationID: '',
        apiToken: '',
        region: null,
		siteID: '',
		siteApiToken: '',
    };


	const validationSchemaForJuniper = yup.object({
            connectionType:yup.string().trim().required("Connection Based On is required!"),
            // organizationName: yup.string().trim().when('connectionType', {
			// 	is: 'Organization',
			// 	then: (schema) => schema.required('Organization Name is required!'),
			// 	otherwise: (schema) => schema.nullable(),
			// }),
			organizationName: yup.string().trim().required('Organization Name is required'),
            organizationID: yup.string().trim().required("Organization ID is required!"),
            apiToken: yup.string().trim().when('connectionType', {
				is: 'Organization',
				then: (schema) => schema.required('API token is required!'),
				otherwise: (schema) => schema.nullable(),
			}),
			siteID: yup.string().trim().when('connectionType', {
				is: 'Site',
				then: (schema) => schema.required('Site ID is required!'),
				otherwise: (schema) => schema.nullable(),
			}),
			siteApiToken: yup.string().trim().when('connectionType', {
				is: 'Site',
				then: (schema) => schema.required('API Token is required!'),
				otherwise: (schema) => schema.nullable(),
			}),
            region: yup.string().trim().required('Region is required!'),
		})

	const currentJuniperValidationSchema = validationSchemaForJuniper;

    const methodsForJuniper  = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(currentJuniperValidationSchema),
        mode: "onChange"
    });

	const { control, formState, handleSubmit, reset, clearErrors, getValues,setValue, trigger, register } = methodsForJuniper

	const { errors } = formState


	const openSyncFunModal = () => {
		setOpenSync0Modal(false);
		setOpenSync1Modal(true);
		handleJuniperConnect()
	}

	const onSubmit = async() => {
		setJuniperTestConnection(true)
		setJuniperTestConnectionIsLoading(true)
		const data = getValues()
		let payload
		const requiredPayload ={
			organizationID: data.organizationID,
			token: data.connectionType === 'Organization' ? data.apiToken : data.siteApiToken, 
			region: data.region.split(":").pop(),
			organizationName: data.organizationName,
		}

		if(data.connectionType === 'Organization'){
			payload ={
				...requiredPayload,
			}
		}else{
			payload ={
				...requiredPayload,
				siteID: data.siteID,
			}
		}

		await axios.post(`/juniper-mist/test-connection`, payload).then((res)=>{
			if(res.status == 200){
				setJuniperTestConnectionIsLoading(false)
				fetchCompanyDetails()
				getConnectorDashboardMatrix()
			}
		}).catch((err)=>{
			console.log(err)
			setJuniperTestConnectionFailed(true)
			setJuniperTestConnectionIsLoading(false)
		})
	}

	const handleJuniperConnect = async() => {
		setJuniperDataSync(true)
		const data = getValues()
		let payload
		const requiredPayload ={
			organizationID: data.organizationID,
			token: data.connectionType === 'Organization' ? data.apiToken : data.siteApiToken, 
			region: data.region.split(":").pop(),
			organizationName: data.organizationName,
		}

		if(data.connectionType === 'Organization'){
			payload ={
				...requiredPayload,
			}
		}else{
			payload ={
				...requiredPayload,
				siteID: data.siteID,
			}
		}
		setOpenJuniperDrawer(false)
		await axios.post(`/juniper-mist/sync-data`, payload).then((res)=>{
			dispatch(toggleProgressBarVisibility(true))
			setJuniperDataSync(false)
		}).catch((err)=>{
			console.log(err)
			setJuniperDataSync(false)
			setJuniperDataSyncFailed(true)
		})
	}

	const handleJuniperDisconnect = async() => {
		await axios.post(`/juniper-mist/disconnect`).then((res)=>{
			if(res.status == 200){
				toast.success(res.data.message)
				fetchCompanyDetails()
				setOpenDisconnectModal(false)
				getConnectorDashboardMatrix()
			} 
		}).catch((err)=>{
			console.log(err)
		})
	}

	const fetchCompanyDetails = async() => {
		setIsLoading(true)
		await axios.get(`/company/fetch-company-details`).then((res)=>{
			const { data } = res.data;
			setCompanyDetails(data);
			if(data?.juniper_token){
				setValue('connectionType', connectionOptions.filter((item) => item.label === data.juniper_organization_type)[0].label)
				setValue('organizationName', data.company_name)
				setValue('organizationID', data.juniper_organization_id ?? '')
				setValue('apiToken', data.juniper_organization_type === 'Site' ? '' : data.juniper_token)
				setValue('region', regionOptions.filter((item) => item.label.split(":").pop() === data.juniper_region)[0].label)
				setValue('siteID', data.juniper_site_id ?? '')
				setValue('siteApiToken', data.juniper_organization_type === 'Site' ? data.juniper_token : '')
				setJuniperConnectionType(data.juniper_organization_type)
				setJuniperSyncDataDate(data.juniper_sync_date ? formatDate(data.juniper_sync_date) : 'N/A')
			}else{
				reset(defaultValues);
			}
		setIsLoading(false)
		}).catch((err)=>{
			console.log(err)
		})
	}

	// console.log('error', errors)
	// console.log('get', getValues())

	//Salto
	const [ saltoConnectionStatus, setSaltoConnectionStatus ] = useState('not connected')
	const [ saltoAccountDetails, setSaltoAccountDetails ] = useState(null)
	function getSaltoAccountDetails(){
		axios.get(`/salto/get-salto-details`)
		.then(response => {
			const { details } = response.data.data;
			setSaltoAccountDetails(details)
			if(details) setSaltoConnectionStatus(details.status)
			else setSaltoConnectionStatus('not connected')
		})
		.catch(err => console.log(err))
	}

	function syncDataFromSalto(){
		axios.post(`/salto/sync-data`)
		.then(response => {
			dispatch(toggleProgressBarVisibility(true))
		})
		.catch(err => console.log(err))
	}

	function handleSaltoDisconnect(){
		axios.post(`/salto/disconnect`).then((res)=>{
			getSaltoAccountDetails()
			setOpenDisconnectModal(false)
			getConnectorDashboardMatrix()
		}).catch((err)=>{
			console.log(err)
		})
	}

	function getConnectorDashboardMatrix(){
		axios.get(`/juniper-mist/connector-dashboard-matrix`).then((res)=>{
			setConnectorDashboardMatrix((prev)=>{
				return{
					...prev,
					connected: res.data.data.matrix.connected,
					disconnected: res.data.data.matrix.disconnected,
				}
			})
		}).catch((err)=>{	
			console.log(err)
		})
	}

	useEffect(()=>{
		if(broadcastChannel){
			broadcastChannel.bind('data_sync_status', (data) => {
				getSaltoAccountDetails()
			})
			return () => broadcastChannel.unbind('data_sync_status')
		}
	},[broadcastChannel]);

	useEffect(()=>{
		getSaltoAccountDetails()
	},[openSaltoDrawer]);

	useEffect(() =>{
		fetchCompanyDetails()
		getConnectorDashboardMatrix()
	},[])


	const bySearch = (item, search) => {
		if (search) {
		  return item.toLowerCase().includes(search.toLowerCase());
		} else return item;
	  };

	const filteredList = (items, search) => {
		return items
		  .filter(item => bySearch(item, search));
	  };

	return (
		<>
			<div className='two_columns_holder connector_outer_holder'>
				<div className='left_holder'>
					<div className='heading_holder each_block'>
						{/* <div className='left_holder'>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M3.99805 8H11.998M1.99805 4H13.998M5.99805 12H9.99805" stroke="#1D2939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							<Typography variant='h6'>Filter By</Typography>
						</div> */}
						{/* <div className='right_holder'>Reset Changes</div> */}
					</div>
					<div className='each_block search_holder'>
						<TextField
							className='search_input'
							placeholder="Search..."
							variant="outlined"
							fullWidth
							onChange={e => setSearch(e.target.value)}
							sx={{
								'& .MuiOutlinedInput-root': {
								  borderRadius: '8px'
								},
								'& .MuiOutlinedInput-input': {
									padding: '10px 12px'
								}
							}}
						/>
					</div>
					<FormGroup className='checkbox_form_holder'>
						{/* Select All Checkbox */}
						<FormControlLabel
							control={
							<Checkbox
								//indeterminate={someSelected}
								checked={allSelected}
								onChange={handleSelectAll}
								sx={{
									color: '#EAECF0',
									width: '16px',
									height: '16px',
									'&.Mui-checked': {
									  color: '#F66D69',
									},
									'&.Mui-checked .MuiSvgIcon-root': {
									  backgroundColor: 'transparent',
									},
									'&.MuiCheckbox-indeterminate': {
										color: '#F66D69',
									},
									'& .MuiSvgIcon-root': {
									  backgroundColor: 'transparent', 
									  borderRadius: '4px',
									},
									'&:hover': {
										backgroundColor: 'transparent',
									},
									'&.Mui-checked:hover': {
										backgroundColor: 'transparent',
									}
								}}
							/>
							}
							label="All"
							labelPlacement="start"
						/>

						{/* Individual Checkboxes */}
						{filteredList(items, search).map((item) => (
							<FormControlLabel
								key={item}
								control={
									<Checkbox
										checked={selectedItems.includes(item)}
										onChange={(event) => handleItemChange(event, item)}
										sx={{
											color: '#EAECF0',
											width: '16px',
											height: '16px',
											'&.Mui-checked': {
											  color: '#F66D69',
											},
											'& .MuiSvgIcon-root': {
											  backgroundColor: 'transparent', 
											  borderRadius: '4px',
											},
											'&.Mui-checked .MuiSvgIcon-root': {
											  backgroundColor: 'transparent',
											},
											'&:hover': {
												backgroundColor: 'transparent', 
											},
											'&.Mui-checked:hover': {
												backgroundColor: 'transparent',
											}
										}}
									/>
								}
								label={item}
								labelPlacement="start"
							/>
						))}
					</FormGroup>
				</div>

				<div className='right_content_holder relative'>	

					{dataSyncReducer.isProgressBarVisible && <div className='data_status_tooltip_holder'>
						<IconRefresh />
						<span className='pl-4 pr-8'>Data sync {dataSyncMessageBasedOnStatus[dataSyncReducer.syncStatus]}:</span>
						<span className='underlined_txt' onClick={handleOpenDataSyncStatusModal}>Check Status</span>
					</div>}

					<div role="presentation" className={`cstm_breadcrumbs_holder ${!dataSyncReducer.isProgressBarVisible ? 'pt-0' : ''}`}>
						<Breadcrumbs aria-label="breadcrumb">
							<Link underline="hover" color="inherit">
								Dashboard
							</Link>
							<span>Marketplace</span>
						</Breadcrumbs>
					</div>
					<h4 className="main_page_heading mb-20">Integrations</h4>

					{selectedItems.includes('Network Access Control') &&
						<Box sx={{ display: 'inline-flex', backgroundColor: '#e9ebed', padding: '8px', borderRadius:'8px'}}>
							<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='category_tabs'>
								<Tab className="tabs_exampleText" label={`All (${connectorDashboardMatrix.all})`} />
								<Tab className="tabs_exampleText" label={`Connected (${connectorDashboardMatrix.connected?.length ?? 0})`} />
								<Tab className="tabs_exampleText" label={`Disconnected (${connectorDashboardMatrix.disconnected?.length ?? 0})`} />
								<Tab className="tabs_exampleText" label={`Broken (${connectorDashboardMatrix.broken})`} />
							</Tabs>	
						</Box>
					}

				{!isLoading && selectedItems.includes('Network Access Control') &&
					<div className='connectors_block_holder'>
						{connectors.map((eachConnector, index) => eachConnector.visible && (
							<div className={`each_connector_block ${eachConnector.syncType}`} key={index}>
								<div className='top_holder'>
									{eachConnector.name == 'Juniper' &&
										<img className='logo_holder' src={juniperImg} alt="juniper-logo" />
									}
									{eachConnector.name == 'Salto' &&
										<img className='logo_holder' src={saltoImg} alt="salto-logo" />
									}
									{eachConnector.name == 'Slack' &&
										<img className='logo_holder' src={slackImg} alt="slack-logo" />
									}
									{eachConnector.name == 'Juniper' && companyDetails?.juniper_status === 'connected' && companyDetails?.juniper_token &&
									 <span className='updated_status'>Last synced: {juniperSyncDataDate}</span>
									}
									{eachConnector.name == 'Salto' && saltoConnectionStatus == 'connected' &&
									 	<span className='updated_status'>
											Last synced: {saltoAccountDetails.last_sync ? formatDate(saltoAccountDetails.last_sync) : 'N/A'}
										</span>
									}
								</div>
								<div className='heading_holder'>
									<h4 className='heading'>{eachConnector.name}</h4>
									{companyDetails?.juniper_status == 'connected' && eachConnector.name === 'Juniper' &&
										<span className={`badge_holder ${eachConnector.syncType.toLowerCase()}`}>
											{eachConnector.syncType}
										</span>
									}
									{eachConnector.name === 'Salto' &&
										<span className={`badge_holder ${(saltoConnectionStatus === 'disconnected' || saltoConnectionStatus === 'not connected') ? 'not_connected' : 'connected'}`}>
											{(saltoConnectionStatus === 'disconnected' || saltoConnectionStatus === 'not connected') ? 'Not Connected' : saltoConnectionStatus}
										</span>
									}
									{companyDetails?.juniper_status == 'disconnected' && eachConnector.name === 'Juniper' &&
										<span className={`badge_holder not_connected`}>
											{'Not Connected'}
										</span>
									}
								</div>
								{/* type */}
								<div className='type_holder'>{eachConnector.type}</div>

								{/* description */}
								<div className='desc_holder'>{eachConnector.desc}</div>

								{user?.role[0] === 'Super-User' &&
								<div className='bottom_btn_holder'>
									{companyDetails?.juniper_status == 'connected'  && eachConnector.name === 'Juniper' &&
										<Button disabled={companyDetails?.juniper_status === 'disconnected'} className='primary_maroon_outlined_btn small_padding' style={{cursor: companyDetails?.juniper_status === 'disconnected' ? 'not-allowed' : 'pointer' }} onClick={handleJuniperConnect}>Sync Data</Button>
									}
									{(companyDetails?.juniper_status == 'disconnected' || !companyDetails?.juniper_status) && eachConnector.name === 'Juniper' &&
										<Button className='primary_btn small_padding mt-2' onClick={() => handleOpenForm(eachConnector.name)}>Connect</Button>
									}
									{companyDetails?.juniper_token && companyDetails?.juniper_status === 'connected' && eachConnector.name === 'Juniper' &&
										<>
											<Button aria-describedby={id} variant="contained" onClick={(e)=>handleClick(e, 'juniper')} className='light_grey_btn py-4'>
												<IconDots />
											</Button>
										{connectorName === 'juniper' &&
											<Popover
												id={id}
												open={open}
												anchorEl={anchorEl}
												onClose={handleClose}
												anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left',
												}}
											>
												<ul className='cstm_popover_list_holder'>
													{companyDetails?.juniper_token && companyDetails?.juniper_status !== 'disconnected' &&
														<li onClick={() => setOpenDisconnectModal(true)}>Disconnect</li>
													}
													<li onClick={() => handleOpenForm('Juniper',true)}>Edit Details</li>
													<li onClick={() => setOpenHowWorksModal(true)}>How it works</li>
												</ul>
											</Popover>
										}
										</>
									}
									{(!companyDetails?.juniper_token || companyDetails?.juniper_status == 'disconnected') && eachConnector.name === 'Juniper' &&
										<div className='link_holder' onClick={() => setOpenHowWorksModal(true)}>How it works?</div>
									}
									{/* Salto */}
									{eachConnector.name === 'Salto' && saltoConnectionStatus == "not connected" &&
										<Button className='primary_btn small_padding' onClick={() => handleOpenForm(eachConnector.name)}>Connect</Button>
									}
									{eachConnector.name === 'Salto' && (saltoConnectionStatus == "connected" || saltoConnectionStatus === 'disconnected') &&
										<Button
											className='primary_maroon_outlined_btn small_padding'
											onClick={syncDataFromSalto}
											disabled={saltoAccountDetails.status === "disconnected"}
										>Sync Data</Button>
									}
									{eachConnector.name === 'Salto' && (saltoConnectionStatus == "connected" || saltoConnectionStatus === 'disconnected') &&
										<>
											<Button aria-describedby={id} variant="contained" onClick={(e)=>handleClick(e, 'salto')} className='light_grey_btn py-4'>
												<IconDots />
											</Button>
											{connectorName === 'salto' &&
												<Popover
													id={id}
													open={open}
													anchorEl={anchorEl}
													onClose={handleClose}
													anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'left',
													}}
												>
													<ul className='cstm_popover_list_holder'>
														{saltoAccountDetails.status !== "disconnected" && <li onClick={() => setOpenDisconnectModal(true)}>Disconnect</li>}
														<li onClick={() => handleOpenForm('Salto')}>Edit Details</li>
														<li onClick={() => setOpenHowWorksModal(true)}>How it works</li>
													</ul>
												</Popover>
											}
										</>
									}
									{/* {eachConnector.syncType == 'not connected' &&
										<div className='link_holder' onClick={() => setOpenHowWorksModal(true)}>How it works?</div>
									} */}
									{eachConnector.syncType == 'broken' &&
										<Button className='primary_btn small_padding' onClick={() => setOpenReconnectModal(true)}>Reconnect</Button>
									}
									{eachConnector.syncType == 'broken' &&
										<div className='link_holder error' onClick={() => setOpenAuthErrorModal(true)}>Error Details</div>
									}		
								</div>
								}
							</div>
						))}
					</div>	
				}	
				{
					(connectors.every((item)=>item.visible === false)) && <div style={{textAlign: 'center', fontSize:'16px'}}>
						No connectors found
					</div>
				}
				{!selectedItems.includes('Network Access Control') &&
					<div style={{textAlign: 'center', fontSize:'16px'}}>
						No connectors found
					</div>
				}							
				</div>
			</div>

			{/* juniper drawer */}
			<Drawer anchor='right' open={openJuniperDrawer} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder bottom_border'>
                <div className='heading_holder'>
                    <h4 className='heading'> {!isEditing ? 'Connect' : 'Edit'} Juniper</h4>
                    <IconButton onClick={() => handleJuniperCloseForm()} style={{padding: '0px'}}>
                        <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                    </IconButton>
                </div>

				<div className='cstm_wizard_holder'>
					<Box sx={{ width: '100%', paddingTop: '30px' }} className='box_holder'>
						<div className='main_content_holder'>
							<div className='content_holder'>
								<div className="text-left pb-28 border_bottom">
									<div className='mb-10'>
										<Typography className='status_text'>
											Connection Based On
											<span className='required_span'>*</span>
										</Typography>
										<Controller                                                  
											name="connectionType" 
											control={control}
											render={( { field: { ...field } } ) => { 
											return (
												<Autocomplete
													{...field}
													className='main_autocomplete'
													options={connectionOptions}
													onChange={(event, value) => {
														if(value){
														field.onChange(value.label)
														setJuniperConnectionType(value.label);
														}else{
															field.onChange(null)
														setJuniperConnectionType();
														}
													}}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	style={{background: 'white'}}
																	{...params}
																	// label="Status"
																	required
																	error={!!errors.connectionType}
																	helperText={errors?.connectionType?.message && 'Connection Based On is required!'}
																	FormHelperTextProps={{
																		style: { 
																			margin : 0, 
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px',
																			fontSize: '10px',
																			color: '#f44336',
																		}
																	}}
																	variant="outlined"
																	fullWidth
																/>
															</div>
														)
													}}
												/>
												)
											}}
									/>
									</div>

										<div className='mb-10'>
											<Typography className='status_text'>
												Organization Name
												<span className='required_span'>*</span>
											</Typography>
											<Controller                      
												name="organizationName" 
												control={control}
												render={( { field: {ref, ...field } } ) => { 
												return (
													<TextField 
													{...field}
													required
													name="organizationName"
													variant='outlined'
													fullWidth
													onChange={(event) => {
														field.onChange(event.target.value)
													}}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px',
															fontSize: '10px',
															color: '#f44336',
														}
													}}
													error={!!errors.organizationName}
													helperText={errors?.organizationName?.message}
													style={{ width: '100%', backgroundColor: '#fff' }}
													/>
											)
											}}
										/>
										</div>

										<div className='mb-10'>
											<Typography className='status_text'>
												Organization ID
												<span className='required_span'>*</span>
											</Typography>
											<Controller                      
												name="organizationID" 
												control={control}
												render={( { field: {ref, ...field } } ) => { 
												return (
													<TextField 
													{...field}
												required
												name="organizationID"
												// label='Contract Status'
												variant='outlined'
												fullWidth
												onChange={(event, value) => {
													field.onChange(event.target.value)
												}}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px',
														fontSize: '10px',
														color: '#f44336',
													}
												}}
												error={!!errors.organizationID}
												helperText={errors?.organizationID?.message}
												style={{ width: '100%', backgroundColor: '#fff' }}
												/>
												)
											}}
										/>
										</div>

									{juniperConnectionType === 'Site' &&
										<div className='py-20'>
											<Grid className='grey_container' container spacing={1}>
												<Grid item xs={6}>										
													<Typography className='status_text'>
														Site ID
														<span className='required_span'>*</span>
													</Typography>
													<Controller                      
													name="siteID" 
													control={control}
													render={( { field: {ref, ...field } } ) => { 
													return (
														<TextField 
														{...field}
														required
														name="siteID"
														variant='outlined'
														fullWidth
														onChange={(event) => {
															field.onChange(event.target.value)
														}}
														FormHelperTextProps={{
															style: {
																margin: 0,
																backgroundColor: '#f4f4f4',
																width: '100%',
																paddingTop: '2px',
																fontSize: '10px',
																color: '#f44336',
															}
														}}
														style={{ width: '100%', backgroundColor: '#fff' }}
														error={!!errors.siteID}
														helperText={errors?.siteID?.message}
													/>
													)
												}}
												/>
												</Grid>

												<Grid item xs={6}>										
													<Typography className='status_text'>
														API Token
														<span className='required_span'>*</span>
													</Typography>
													<Controller                      
													name="siteApiToken" 
													control={control}
													render={( { field: {ref, ...field } } ) => { 
													return (
														<TextField 
														{...field}
														required
														name="siteApiToken"
														variant='outlined'
														fullWidth
														onChange={(event) => {
															field.onChange(event.target.value)
														}}
														FormHelperTextProps={{
															style: {
																margin: 0,
																backgroundColor: '#f4f4f4',
																width: '100%',
																paddingTop: '2px',
																fontSize: '10px',
																color: '#f44336',
															}
														}}
														error={!!errors.siteApiToken}
														helperText={errors?.siteApiToken?.message}
														style={{ width: '100%', backgroundColor: '#fff' }}
													/>
													)
												}}
												/>
												</Grid>
											</Grid>

										</div>
									}

								{juniperConnectionType === 'Organization' &&
									<div className='mb-10'>
										<Typography className='status_text'>
											API Token
											<span className='required_span'>*</span>
										</Typography>
										<Controller                      
												name="apiToken" 
												control={control}
												render={( { field: { ref,...field } } ) => { 
												return (
													<TextField 
													{...field}
											required
											name="apiToken"
											variant='outlined'
											fullWidth
											onChange={(event, value) => {
												field.onChange(event.target.value)
											}}
											error={!!errors.apiToken}
											helperText={errors?.apiToken?.message}
											FormHelperTextProps={{
												style: {
													margin: 0,
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px',
													fontSize: '10px',
													color: '#f44336',
												}
											}}
											style={{ width: '100%', backgroundColor: '#fff' }}
										/>
										)
										}}
									/>
									</div>
								}

									<div className='mb-10'>
										<Typography className='status_text'>
											Region
											<span className='required_span'>*</span>
										</Typography>
										<Controller                                                  
											name="region" 
											className="mb-16"
											control={control}
											render={( { field: { ...field } } ) => { 
											return (
										<Autocomplete
											{...field}
											name="region"
											className='main_autocomplete'
											options={regionOptions}
											onChange={(event, value) => {
												if(value){
												field.onChange(value.label)
												}else{
													field.onChange(null)
												}
											}}
											renderInput={(params) => {
												return (
													<div ref={params.InputProps.ref}>
														<TextField
															name="region"
															style={{background: 'white'}}
															{...params}
															// label="Status"
															required
															error={!!errors.region}
															helperText={errors?.region?.message && 'Region is required!'}
															FormHelperTextProps={{
																style: { 
																	margin : 0, 
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px',
																	fontSize: '10px',
																	color: '#f44336',
																}
															}}
															variant="outlined"
															fullWidth
														/>
													</div>
												)
											}}										
											/>
											)
										}}
								/>
										<div className='test_connection' style={{cursor: 'pointer'}} onClick={handleSubmit(onSubmit)}>
											<IconLink/>
											<span className='pl-4'>Test Connection</span>
										</div>
									</div>
								</div>

								<div className="text-left pt-28">
									<h4 className='submain_heading pb-16'>Event Subscription (Optional)</h4>
									<div className='grey_container'>
										<div className='mb-10'>
											<Typography className='status_text'>
												Trigger
											</Typography>
											<Autocomplete
												options={[]}
												disabled
												renderInput={(params) => <TextField {...params} label="" />}
											/>
										</div>
										<div className='mb-10'>
											<Typography className='status_text'>
												Webhook URL
											</Typography>
											<TextField
												required
												name=""
												// label='Contract Status'
												variant='outlined'
												fullWidth
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px',
														fontSize: '10px',
													}
												}}
												style={{ width: '100%', backgroundColor: '#fff' }}
											/>
										</div>
									</div>
									{/* <div className='flex justify-end'>
										<Button
											className='add_anotherButton mt-0 mr-3 p-1 capitalize'
											// onClick={addAnotherFilter}
											startIcon={
												<Icon fontSize="small" title="Add Another">
												add
												</Icon>}>
											Add Another
										</Button>
									</div> */}
								</div>
							</div>
							<div className='btn_holder' style={{cursor: companyDetails?.juniper_status === 'disconnected' ? 'not-allowed' : 'pointer' }}>
								<Button  disabled={!companyDetails?.juniper_token || companyDetails?.juniper_status === 'disconnected'} onClick={handleSubmit(handleJuniperConnect)} className={`primary_btn ${!companyDetails?.juniper_token ? 'disabled' : ''}`}>Sync Data</Button>
							</div>
						</div>
					</Box>
				</div>
			</Drawer>

			{/* salto drawer */}
			{openSaltoDrawer && <ConnectSalto openSaltoDrawer={openSaltoDrawer} handleCloseForm={handleCloseForm} getConnectorDashboardMatrix={getConnectorDashboardMatrix} syncDataFromSalto={syncDataFromSalto}/>}

			{/* reconnecting modal */}
			<Dialog
				className="cstm_dialog_modal small text-center"
				open={openReconnectModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<div className='heading_holder'>
						<div className='loader_animation_holder'>
							<img src={loaderImg} alt='loader' />
							<h4 className='main_heading'>Reconnecting</h4>
						</div>
					</div>
				</DialogTitle>
			</Dialog>

			{/* how it works modal */}
			<Dialog
				className="cstm_dialog_modal small text-center"
				open={openHowWorksModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<div className='heading_holder flex justify-between items-center w-full'>
						<h4 className='main_heading'>Integration Instructions</h4>
						<Button className='transparent_icon_btn' onClick={() => setOpenHowWorksModal(false)}>
							<i className="icon ti ti-x" />
						</Button>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="content_holder">
						<div className='pattern_image_holder'>
							<img src={mainLogo} alt='logo' className='m-2' />
							<img src={ArrowIcon} alt='arrow' className='m-2' />
							<img src={juniperLogoImg} alt='juniper' className='m-2' />
						</div>

						<div className='text-left' style={{height: '80px'}}>
							<p className='normal_text'>Effortlessly and securely integrate your Juniper mist with Dalos platform to enable real-time data synchronization and gain full visibility over your network assets. Follow the steps below to ensure a smooth and successful integration process:</p>
							<ul className='cstm_ul_holder_outer'>
								<li><p className='normal_text_outer'>1. Gather Required Information:</p></li>
									<ul className='cstm_ul_holder_inner'>
										<li><p className='normal_text_inner'>Make sure you have access to your Juniper Networks account.</p></li>
										<li><p className='normal_text_inner'>Obtain your Organization Name, Organization ID, and API Token from your Juniper portal. These details are essential for establishing a secure connection.</p></li>
										<li><p className='normal_text_inner'>The API Token can be generated at the organization level to sync data from all sites or at the site level if you wish to sync data from a specific site.</p></li>
									</ul>
								<li><p className='normal_text_outer'>2. Start the Integration Process:</p></li>
									<ul className='cstm_ul_holder_inner'>
										<li><p className='normal_text_inner'>Click the “Connect Now” button below to open the integration form in Dalos.</p></li>
										<li><p className='normal_text_inner'>Select whether you want to connect based on the entire organization or specific sites.</p></li>
									</ul>
								<li><p className='normal_text_outer'>3. Fill in Your Details:</p></li>
									<ul className='cstm_ul_holder_inner'>
										<li><p className='normal_text_inner'>Organization Name: Enter the exact name of your Juniper organization.</p></li>
										<li><p className='normal_text_inner'>Organization ID: Provide the unique ID associated with your organization.</p></li>
										<li><p className='normal_text_inner'>API Token: Paste the API token that you’ve generated from your Juniper account.</p></li>
										<li><p className='normal_text_inner'>Region: Choose the appropriate region (e.g., Global01:api.mist.com) that corresponds with your Juniper setup.</p></li>
									</ul>
								<li><p className='normal_text_outer'>4. Test the Connection:</p></li>
									<ul className='cstm_ul_holder_inner'>
										<li><p className='normal_text_inner'>After entering your details, click the Test Connection link to verify the integration. This step ensures that your credentials are correct and the connection is valid.</p></li>
										<li><p className='normal_text_inner'>If the test is successful, you’ll receive a confirmation message. If not, double-check your details and try again.</p></li>
									</ul>
								<li><p className='normal_text_outer'>5. Enable Data Synchronization:</p></li>
									<ul className='cstm_ul_holder_inner'>
										<li><p className='normal_text_inner'>Once the connection is verified, click the Sync Data button. This action will initiate the data synchronization process between your Juniper and Dalos platforms.</p></li>
										<li><p className='normal_text_inner'>You’ll start seeing your Juniper host data within Dalos, allowing you to monitor your assets seamlessly.</p></li>
									</ul>
								<li><p className='normal_text_outer'>6. Set Up Event Subscriptions (Optional):</p></li>
									<ul className='cstm_ul_holder_inner'>
										<li><p className='normal_text_inner'>If you want to receive real-time event notifications, complete the Event Subscription section by selecting your preferred triggers and configuring your Webhook URL in juniper mist.</p></li>
									</ul>
							</ul>
						</div>
					</div>					
				</DialogContent>
				<DialogActions className="action_footer_holder">
					{/* <Button className="primary_maroon_outlined_btn small_padding">
						<IconPlayerPlay width={16} />
						<span className='pl-4'>Watch Video</span>
					</Button> */}
					<Button className="primary_maroon_btn">
						Connect Now
					</Button>
				</DialogActions>
			</Dialog>

			{/* disconnect integration modal */}
			<Dialog
				className="cstm_dialog_modal small text-center"
				open={openDisconnectModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<div className='heading_holder flex justify-end items-center w-full'>
						<Button className='transparent_icon_btn' onClick={() => setOpenDisconnectModal(false)}>
							<i className="icon ti ti-x" />
						</Button>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="warning_content_holder">
						<div className="exclamation_icon_holder red">
							<i className="ti ti-exclamation-mark" />
						</div>
						<h2 className="heading">Are you sure to disconnect the integration?</h2>
						<p className="sub_content">You will loss all the process if you proceed</p>
					</div>
				</DialogContent>
				<DialogActions className="action_footer_holder">
					<Button onClick={() => setOpenDisconnectModal(false)} className="secondary_btn">No, go back!</Button>
					{
						connectorName === 'juniper' && <Button onClick={handleJuniperDisconnect} className="primary_btn">
						Yes, Proceed!
						</Button>
					}
					{
						connectorName === 'salto' && <Button onClick={handleSaltoDisconnect} className="primary_btn">
						Yes, Proceed!
						</Button>
					}
				</DialogActions>
			</Dialog>

			{/* authentication error modal */}
			<Dialog
				className="cstm_dialog_modal small text-center"
				open={openAuthErrorModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<div className='heading_holder flex justify-end items-center w-full'>
						<Button className='transparent_icon_btn' onClick={() => setOpenAuthErrorModal(false)}>
							<i className="icon ti ti-x" />
						</Button>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="warning_content_holder">
						<div className="exclamation_icon_holder yellow">
							<i className="ti ti-exclamation-mark white" />
						</div>
						<h2 className="heading">Authentication Error</h2>
						<p className="sub_content">Synchronization failed because we couldn't verify your credentials. Please check your login details.</p>
						<div className='link_holder mt-24 mb-4' onClick={() => setOpenHowWorksModal(true)}>How it works?</div>
					</div>
				</DialogContent>
				<div className="action_footer_holder justify-center">
					<Button style={{maxWidth: '65px'}} className="primary_btn" onClick={() => setOpenHowWorksModal(false)}>
						Close
					</Button>
				</div>
			</Dialog>

			{/* sync confirmation modal */}
			<Dialog
				className="cstm_dialog_modal small text-center"
				open={openSync0Modal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<div className='heading_holder flex justify-end items-center w-full'>
						<Button className='transparent_icon_btn' onClick={() => setOpenSync0Modal(false)}>
							<i className="icon ti ti-x" />
						</Button>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="warning_content_holder">
						<div className="exclamation_icon_holder yellow">
							<i className="ti ti-question-mark white" />
						</div>
						<h2 className="heading">Would you like to sync the data from Juniper now?</h2>
					</div>
				</DialogContent>
				<DialogActions className="action_footer_holder">
					<Button onClick={() => setOpenSync0Modal(false)} className="secondary_btn">No, I'll do it later</Button>
					<Button className="primary_btn" onClick={() => openSyncFunModal()}>
						Yes, go ahead
					</Button>
				</DialogActions>
			</Dialog>

			{/* sync 1 modal */}
			<Dialog
				className="cstm_dialog_modal small text-center"
				open={openSync1Modal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{!syncFailed &&
					<>
						<DialogTitle id="alert-dialog-title">
							<div className='heading_holder flex justify-end items-center w-full'>
								<Button className='transparent_icon_btn' onClick={() => setOpenSync1Modal(false)}>
									<i className="icon ti ti-x" />
								</Button>
							</div>
						</DialogTitle>
						<DialogContent>
							<div className="warning_content_holder">
								<div className='loader_animation_holder'>
									<img src={loaderImg} alt='loader' />
								</div>
								<h2 className="heading">Syncing Your Data</h2>
								<p className="sub_content">Synchronization is underway. This may take a few minutes. You can close this and carry on with your work. We'll notify you when it is done</p>
							</div>
						</DialogContent>
						<div className="action_footer_holder justify-center">
							<Button style={{maxWidth: '65px'}} className="primary_btn" onClick={() => syncCloseModal()}>
								Close
							</Button>
						</div>
					</>
				}
				{syncFailed &&
					<>
						<DialogTitle id="alert-dialog-title">
							<div className='heading_holder flex justify-end items-center w-full'>
								<Button className='transparent_icon_btn' onClick={() => closeSyncFailed()}>
									<i className="icon ti ti-x" />
								</Button>
							</div>
						</DialogTitle>
						<DialogContent>
							<div className="warning_content_holder">
								<div className="exclamation_icon_holder red">
									<i className="ti ti-exclamation-mark white" />
								</div>
								<h2 className="heading">Synchronization Failed</h2>
								<p className="sub_content">Something went wrong during synchronization. Please try again later.</p>
							</div>
						</DialogContent>
						<div className="action_footer_holder justify-center">
							<Button style={{maxWidth: '65px'}} className="primary_btn" onClick={() => setOpenSync1Modal(false)}>
								Retry
							</Button>
						</div>
					</>
				}
			</Dialog>

			{/* status checking modal */}
			{openDataSyncStatusModal && <DataSyncStatusModal
				open={openDataSyncStatusModal}
				close={handleCloseDataSyncStatusModal}
				/>}
			
			<Dialog
				className="cstm_dialog_modal small text-center"
				open={juniperTestConnection}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{juniperTestConnectionIsLoading &&
					<>
						<DialogTitle id="alert-dialog-title">
							<div className='heading_holder flex justify-end items-center w-full'>
								<Button className='transparent_icon_btn' onClick={() => juniperTestConnectionCloseModal()}>
									<i className="icon ti ti-x" />
								</Button>
							</div>
						</DialogTitle>
						<DialogContent>
							<div className="warning_content_holder">
								<div className='loader_animation_holder'>
									<img src={loaderImg} alt='loader' />
								</div>
								<h2 className="heading">Establishing Connection</h2>
								<p className="sub_content">Attempting to connect to the API. This may take a few seconds.</p>
							</div>
						</DialogContent>
						<div className="action_footer_holder justify-center">
							<Button style={{maxWidth: '65px'}} className="primary_btn" onClick={() => juniperTestConnectionCloseModal()}>
								Close
							</Button>
						</div>
					</>
				}
				{!juniperTestConnectionFailed && !juniperTestConnectionIsLoading && juniperTestConnection &&
					<>
						<DialogTitle id="alert-dialog-title">
							<div className='heading_holder flex justify-end items-center w-full'>
								<Button className='transparent_icon_btn' onClick={() => juniperTestConnectionCloseModal()}>
									<i className="icon ti ti-x" />
								</Button>
							</div>
						</DialogTitle>
						<DialogContent>
							<div className="warning_content_holder green">
							<div className="exclamation_icon_holder green">
								<i className="ti ti-check white" />
							</div>
								<h2 className="heading">Connection Successful</h2>
								<p className="sub_content">Your API connection was established successfully. You can now proceed with your requests.</p>
							</div>
						</DialogContent>
						<div className="action_footer_holder justify-center">
							<Button style={{maxWidth: '65px'}} className="primary_btn" onClick={() => juniperTestConnectionCloseModal()}>
							Close
							</Button>
						</div>
					</>
				}
				{juniperTestConnectionFailed && !juniperTestConnectionIsLoading && juniperTestConnection &&
					<>
						<DialogTitle id="alert-dialog-title">
							<div className='heading_holder flex justify-end items-center w-full'>
								<Button className='transparent_icon_btn' onClick={() => juniperTestConnectionCloseModal()}>
									<i className="icon ti ti-x" />
								</Button>
							</div>
						</DialogTitle>
						<DialogContent>
							<div className="warning_content_holder">
								<div className="exclamation_icon_holder red">
									<i className="ti ti-exclamation-mark white" />
								</div>
								<h2 className="heading">Connection Failed</h2>
								<p className="sub_content">Your API connection was failed. Please try again later.</p>
							</div>
						</DialogContent>
						<div className="action_footer_holder justify-center">
							<Button style={{maxWidth: '65px'}} className="primary_btn" onClick={() => onSubmit()}>
								Retry
							</Button>
						</div>
					</>
				}
			</Dialog>
		</>
	)
}

export default withReducer('ConnectorsApp', reducer)(Connector)